"use client";
import React, { useContext, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import DOMPurify from 'dompurify';
import useRefferalHook from "../../../hooks/useRefferal.hook";
import { ContextData } from "@/services/Methods/normalMethods";
import Loader from "@/comman/Loader";
const Home = (props) => {
  const [playVideo1, setPlayVideo1] = useState(false);
  const [playVideo2, setPlayVideo2] = useState(false);
  const [playVideo3, setPlayVideo3] = useState(false);

  console.log("playVideo1**************", playVideo1, "playVideo2****************", playVideo2, "playVideo3*********", playVideo3)
  const data = useContext(ContextData);
  const { isReferralLoading } = useRefferalHook({ ...props, ...data }, 'urlRefferalCheck');
  const slides = [
    {
      video: "https://player.vimeo.com/video/1004141898?autoplay=1&controls=1&byline=0&title=0",
      title: "WE LIVE",
      subtitle: "Live More. Live Fully. Live Amplified.",
      buttonText: "See How We Thrive",
      buttonLink: "#",
      backgroundImage: "/images/web-viago-1.jpg",
      playVideoMethod: () => setPlayVideo1(true),
      playVideoState: playVideo1,

    },
    {
      video: "https://player.vimeo.com/video/996383457?autoplay=1&byline=0&title=0",
      title: "WE THRIVE",
      subtitle: "Living and Working... to Live.",
      description: `We all face the challenge of balancing work and life. 
        We work to live, yet often sacrifice life for our work. But today, there's a different way forward. 
        Your side hustle doesn’t have to mean working overtime—it can mean creating more time for what truly matters. 

        <p> Viago helps you achieve just that: more freedom in your
        daily life and bigger rewards for your eorts, all while
        having fun along the way. </p>`,
      buttonText: null,
      buttonLink: null,
      backgroundImage: "/images/background_image.jpg",
      playVideoMethod: () => setPlayVideo2(true),
      playVideoState: playVideo2,
    },
    {
      video: "images/updated-coming-soon.jpg",
      title: "WE GROW",
      subtitle: "We Are a Global Community.",
      description: `Welcome to our global community – a family united by the belief that there’s
      a better way to live each day. Together, we can achieve more, move faster,
      and thrive in ways we never imagined.
      <br/>
     <p> Here, we provide the support, insights, and "cheat codes" to help you reach
your goals quicker. Our most successful members are here to share their
knowledge and inspire you to go further than you ever thought possible. Join
us and discover how, when we come together, we all grow and succeed </p>`,
      buttonText: "See How We Grow",
      buttonLink: "#",
      backgroundImage: "/images/web-viago-4.jpg",
      playVideoMethod: () => setPlayVideo3(true),
      playVideoState: playVideo3,
    },
  ];

  return (
    isReferralLoading ? (
      <Loader />
    ) : (
      <div className="main main-slider-section-cstm" style={{ backgroundColor: "black" }}>
        <Swiper
          className="slider_inner"
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={50}
          slidesPerView={1}
          navigation={true}
          pagination={{ clickable: true }}
        >
          {slides.map((slide, index) => (
            <SwiperSlide key={index}>
              <div className="lg:max-w-[1920px] mx-auto z-[2] flex flex-wrap flex-row-reverse">
                <div className="video_inner lg:w-1/2 w-full flex items-center justify-center 2xl:min-h-[630px] xl:min-h-[520px] md:min-h-[590px] min-h-[320px]">
                  {slide.video.endsWith(".jpg") || slide.video.endsWith(".png") ? (
                    <img
                      src={slide.video}
                      alt="Slide"
                      className="w-full h-full object-cover"
                    />
                  ) : !slide.playVideoState ? (
                    <div
                      className="video-thumbnail w-full h-full bg-cover bg-center relative"
                      style={{
                        backgroundImage: `url(${slide.backgroundImage})`,
                      }}
                      onClick={slide?.playVideoMethod}
                    >
                      <button
                        onClick={slide?.playVideoMethod}
                        className="absolute w-[150px] h-[150px] rounded-full flex items-center justify-center text-black text-2xl font-bold"
                        style={{
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        <img src="/images/PlayArrow.png" />
                      </button>

                    </div>
                  ) : (
                    <figure className="lg:flex-1 block h-full max-xsm:max-w-[300px] max-xsm:mx-auto">
                      <iframe
                        src={slide.video}
                        style={{ width: "100%", height: "100%" }}
                        allow="autoplay; fullscreen"
                        allowFullScreen
                        className="w-full h-full"
                      ></iframe>
                    </figure>
                  )}
                </div>


                <div
                  className="lg:flex-1 text-white lg:p-[144px_72px_144px_72px] p-[21px_20px_76px_20px] text-[28px] lg:w-1/2 relative flex flex-col lg:justify-center lg:items-start items-center slider-content-with-bg w-full"
                  style={{
                    backgroundImage: `url(${slide.backgroundImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <div className="z-[2] lg:text-start text-center">
                    <h2 className=" lg:text-[45px] text-[36px] futura_font font-medium tracking-[3px]">
                      {slide.title}
                    </h2>
                    <h4
                      className="pt-[9px] leading-[32.4px] pb-[18px] lg:text-[21.6px] text-[18px] futura_font_md z-20"
                      style={{ color: "#0077f7" }}
                    >
                      {slide.subtitle}
                    </h4>
                    {slide.description && (
                      <div>
                        <p
                          className="pb-[18px] lg:text-[18px] text-[16px] futura_font_md font-se lg:leading-[27px] leading-[26px]"
                          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(slide.description) }}
                        ></p>

                        {index === 0 ? (
                          <p className="lg:text-[17px] text-[16px] futura_font_md font-medium lg:leading-[27px] leading-[26px] italic">
                            Viago helps you achieve just that: more freedom in your daily life and bigger rewards for
                            your efforts, all while having fun along the way.
                          </p>
                        ) : null}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    ))
};
export default Home;
